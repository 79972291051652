import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import WidgetYoutube from './WidgetYoutube'
import WidgetLatestPosts from './WidgetLatestPosts'
import WidgetFutureEvents from './WidgetFutureEvents'
import Ads from '../content/Ads'

const SidebarHome = () => {
  const { wp: data } = useStaticQuery(graphql`
    query HomeSidebarQuery {
      wp {
        futureEvents: events(
          first: 5
          where: {
            scope: FUTURE
            status: PUBLISH
            language: DEFAULT
            orderby: {
              order: ASC
              field: START_DATE
            }
          }
        ) {
          nodes {
            ...EventFields
          }
        }

        latestPosts: posts(
          first: 5
          where: {
            status: PUBLISH
            language: DEFAULT
            orderby: {
              field: DATE
              order: DESC
            }
          }
        ) {
          nodes {
            ...PostFields
          }
        }
      }
    }
  `)

  return (
    <aside>
      <Ads placement="zone1MainPage" only="desktop" location="mainpage"/> 
      <WidgetFutureEvents events={data.futureEvents.nodes} />     
      <WidgetYoutube />
      <WidgetLatestPosts posts={data.latestPosts.nodes} />
      <Ads placement="zone1MainPage" only="mobile" location="mainpage"/>
    </aside>
  )
}

export default SidebarHome
