import React, { lazy } from 'react'
import { graphql } from 'gatsby'
import { LayoutSection } from '../components/layout'
import { PostList } from '../components/posts'
import { Pagination, Lazy } from '../components/content'
import { FeaturedLinks } from '../components/links'
import {
  Grid, GridItem, Box, Heading, Placeholder,
} from '../components/ui'
import { WidgetTodaysEvents, WidgetEventCalendar, SidebarHome } from '../components/sidebar'
import { SchemaWebsite, SchemaOrganization } from '../components/seo'
import PostPreview from '../components/posts/PostPreview'
import Ads from '../components/content/Ads'

const PostCarousel = lazy(() => import('../components/posts/PostCarousel'))

const HomeTemplate = ({ data, pageContext }) => {
  const { galleryCategory, featuredLinkGroups } = data.wp
  const { nodes: stickyPosts } = data.wp.stickyPosts
  const { nodes: latestPosts } = data.wp.latestPosts
  const { edges: galleryPosts } = data.wp.galleryPosts

  const firstFeaturedLinks = featuredLinkGroups.slice(0, 4)
  const restOfFeaturedLinks = featuredLinkGroups.slice(4)

  const topPost = stickyPosts?.[0]
  const firstPosts = latestPosts.slice(1, 7)
  const restOfPosts = latestPosts.slice(7)

  return (
    <>
      <SchemaWebsite />
      <SchemaOrganization />
      <Ads placement="magnumMainPage" only="mobile" location="mainpage"/>
      <Grid my={-3}>
        <GridItem
          py={3}
          order={0}
          width={[1, 1, 2 / 3]}
        >
          <PostPreview
            topstory = {true}
            {...topPost}
          />
        </GridItem>
        <GridItem
          py={3}
          order={[1, 1, 3]}
          width={[1, 1, 1 / 3]}
        >
          <WidgetTodaysEvents />
          <WidgetEventCalendar />
        </GridItem>
      </Grid>
      <Ads placement="magnumMainPage" only="desktop" location="mainpage"/>
      <Grid pb={3}>
        <GridItem
          margin="0 auto"
          width={[1, 1, 2 / 3]}
        >
          <LayoutSection heading="Friss cikkek">
            <section>
              <PostList posts={firstPosts} />
            </section>
            <Ads placement="zone2MainPage" mt={10} only="mobile" location="mainpage"/>
            <Lazy fallback={<Placeholder height={198} />}>
              <Box
                mb={4}
                mx={[-3, -3, 0]}
                as="section"
              >
                <Heading
                  as="h2"
                  fontFamily={'Merriweather, serif'}
                  my={2}
                  pl={[2, 2, 0]}
                  fontSize={3}
                >
                  {galleryCategory.name}
                </Heading>
                <PostCarousel posts={galleryPosts} />
              </Box>
            </Lazy>
            <Ads placement="zone2MainPage" mt={10} only="desktop" location="mainpage"/>
            <section>
              <PostList posts={restOfPosts} />
            </section>

            <Pagination
              {...pageContext}
              nextText="Még több cikk"
            />
          </LayoutSection>
        </GridItem>
        <GridItem width={[1, 1, 1 / 3]}>
          <SidebarHome />
        </GridItem>
      </Grid>
    </>
  )
}

export default HomeTemplate

export const pageQuery = graphql`
  query IndexQuery($first: Int!) {
    wp {
      featuredLinkGroups {
        title
        url
        links {
          title
          url
        }
      }

      stickyPosts: posts(
        first: 1
        where: {
          status: PUBLISH
          language: DEFAULT
          orderby: { field: DATE, order: DESC }
        }
      ) {
        nodes {
          ...PostListFields
          ...stickyListImageFields
        }
      }

      latestPosts: posts(
        first: 11
        where: {
          status: PUBLISH
          language: DEFAULT
          orderby: { field: DATE, order: DESC }
        }
      ) {
        nodes {
          ...PostListFields
          ...PostListImageFields
        }
      }

      galleryCategory: category(idType: SLUG, id: "tisza-to-foto-video") {
        name
      }

      galleryPosts: posts(
        first: $first
        where: {
          status: PUBLISH
          language: DEFAULT
          orderby: { field: DATE, order: DESC }
          categoryName: "tisza-to-foto-video"
        }
      ) {
        edges {
          node {
            uri
            title
            featuredImage {
              node {
                src: sourceUrl(size: SMALL_POST)
                srcSet(size: SMALL_POST)
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`
